import React, { useContext } from 'react';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { TopMenuWithOverlayPanel } from 'og-merchant-portal-react-library';
import styled from 'styled-components';
import locales from './LanguageSelector/locale';
import I18nContext from '../../libs/IntlProvider/IntlContext';
import { KeycloackContext } from '../../libs/KeycloakProvider/KeycloakProvider';

const HeaderWrapper = styled.div`
  height: 80px;
  margin-left: 205px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  img {
    height: 37px;
    margin: auto 0;
    padding-left: 40px;
    display: block;
  }
  @media (max-width: 992px) {
    margin: 0 25px;
    #TopImg {
      display: none;
    }
  }
`;

const Navigation = (props) => {
  const { availableTranslations } = useContext(I18nContext);
  const { logoutUserFromKeycloak } = useContext(KeycloackContext);
  const availableLocalesAndTranslations = Object.keys(locales).length !== 0 && availableTranslations.length > 1;

  const handleOnHelpMenuItemClick = (targetItem) => {
    if (targetItem?.url) {
      window.open(targetItem.url);
    }
  };

  return (
    <HeaderWrapper>
      <img id={'TopImg'} src="/themes/logo-expanded.svg" alt={'logo'} />
      <TopMenuWithOverlayPanel
        items={[]}
        logoUrl={`/themes/logo-expanded.svg`}
        languageSelectorComponent={availableLocalesAndTranslations ? <LanguageSelector /> : null}
        rightLogoutLinkUrl={'/logout'}
        rightLogoutLinkLabel={'logout'}
        onNavigationItemClick={(item) => {
          item.id
            ? item.id === 'logout'
              ? logoutUserFromKeycloak()
              : props.history.push(item.url)
            : logoutUserFromKeycloak();
        }}
        helpMenuItem={[]}
        onHelpMenuItemClick={handleOnHelpMenuItemClick}
      />
    </HeaderWrapper>
  );
};

export default Navigation;
