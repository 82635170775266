import React, { useContext } from 'react';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import styled from 'styled-components';
import { UserInfoContext, SpinnerLoading } from 'og-merchant-portal-react-library';
import useFetch from '../../utils/useFetch';
const ApplicationsWrapper = styled.div`
  margin: 56px ${(props) => (props.nbrApplications === 4 ? '20%' : '245px')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.nbrApplications === 4 ? 'center' : 'flex-start')};
  column-gap: ${(props) => (props.nbrApplications === 4 ? '10%' : '0')};
  min-height: 404px;

  @media (max-width: ${(props) => props.theme.medias.xs.max}px) {
    margin: 0 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

const Applications = () => {
  const user = useContext(UserInfoContext);
  const { apiData, apiLoading } = useFetch('/user/applications');
  
  if (!user) {
    return <SpinnerLoading />;
  }

  return (
    <ApplicationsWrapper nbrApplications={apiData ? apiData.length : 0}>
      {apiData && !apiLoading ? (
        apiData.map((application, i) => (
          <ApplicationCard
            key={`${application.name}`}
            name={application.name}
            link={application.link}
            index={i}
            nbrApplications={apiData ? apiData.length : 0}
          />
        ))
      ) : (
        <SpinnerLoading />
      )}
    </ApplicationsWrapper>
  );
};

export default Applications;
