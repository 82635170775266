// eslint-disable-next-line import/no-anonymous-default-export
export default {
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Français",
    it: "Italiano",
    nl: "Nederlands",
};
  
